<template>
  <div class="wrapper call-for-presenters">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Call For Presenters</h2>
            <h4>Do you have a topic you'd like to present?</h4>
          </div>
        </div>
      </div>
    </div>
    
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 ml-auto mr-auto">
            <div class="card shadow">
              <div class="card-body p-md-5 p-4">
                <h2 class="section-title">UMC Call for Presenters</h2>
                <p class="lead mb-4">
                  We are looking forward to another wonderful workshop and conference. If you have a topic you'd like to present or know someone who might, please complete the online form.
                </p>
                
                <div class="features">
                  <div class="feature">
                    <div class="feature-left">
                      <div class="feature-icon icon-primary">
                        <i class="now-ui-icons business_bulb-63"></i>
                      </div>
                    </div>
                    <div class="feature-content">
                      <h4>Share Your Knowledge</h4>
                      <p>We welcome presentations on innovative teaching methods, classroom management, Montessori philosophy, and other relevant topics.</p>
                    </div>
                  </div>
                  
                  <div class="feature">
                    <div class="feature-left">
                      <div class="feature-icon icon-info">
                        <i class="now-ui-icons education_hat"></i>
                      </div>
                    </div>
                    <div class="feature-content">
                      <h4>Professional Development</h4>
                      <p>Your presentation contributes to the ongoing professional development of our community of Montessori educators.</p>
                    </div>
                  </div>
                </div>
                
                <div class="text-center my-5">
                  <a
                    href="https://forms.gle/59eM8d39qhn857nq7"
                    target="_blank"
                    class="btn btn-primary btn-lg btn-round px-5"
                  >Submit Proposal</a>
                </div>
                
                <div class="contact-info">
                  <h3>Contact Information</h3>
                  <hr class="divider">
                  <address>
                    <strong>Duna Strachan, MEd</strong><br>
                    UMC Conference Coordinator<br>
                    2083 Equestrian Court<br>
                    Park City UT 84060<br>
                    <abbr title="Phone">P:</abbr> 435-659-1493<br>
                    <abbr title="Email">E:</abbr> <a href="mailto:duna@soaringwings.org">duna@soaringwings.org</a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "call-for-presenters",
  bodyClass: "call-for-presenters",
  data() {
    return {
      conference: "21st Annual Conference",
      confPlace: "Salt Lake Marriott University Park",
      confDate: "TBD",
      confCity: "Salt Lake City",
    };
  },
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Call For Presenters",
    meta: [
      {
        name: "description",
        content:
          "If you have a topic you would like to present or know someone who might?",
      },
    ],
  },
};
</script>
<style>
.call-for-presenters .section {
  padding: 60px 0;
}

.call-for-presenters .section-title {
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2c2c2c;
  position: relative;
  font-size: 2rem;
}

.call-for-presenters .lead {
  font-size: 1.1rem;
  color: #525f7f;
  font-weight: 400;
  line-height: 1.7;
}

.call-for-presenters .card.shadow {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.call-for-presenters .features {
  margin: 3rem 0;
}

.call-for-presenters .feature {
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: 2.5rem;
}

.call-for-presenters .feature-left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4px;
}

.call-for-presenters .feature-icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.call-for-presenters .icon-primary {
  background: linear-gradient(to bottom right, #f5593d, #f96332);
}

.call-for-presenters .icon-info {
  background: linear-gradient(to bottom right, #4c7261, #5a706b);
}

.call-for-presenters .feature-content {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.call-for-presenters .feature-content h4 {
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.4;
}

.call-for-presenters .feature-content p {
  margin-bottom: 0;
  color: #525f7f;
}

.call-for-presenters .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1rem 0 1.5rem;
}

.call-for-presenters .contact-info {
  background-color: #f7f7f7;
  padding: 1.5rem;
  border-radius: 0.3rem;
}

.call-for-presenters .contact-info h3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2c2c2c;
}

.call-for-presenters address {
  font-style: normal;
  line-height: 1.6;
  margin-bottom: 0;
}

.call-for-presenters address a {
  color: #f96332;
}

@media (max-width: 768px) {
  .call-for-presenters .feature {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .call-for-presenters .feature-left {
    margin-bottom: 1rem;
  }
  
  .call-for-presenters .feature-content {
    align-items: center;
  }
  
  .call-for-presenters .section-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .call-for-presenters .section-title {
    font-size: 1.6rem;
  }
  
  .call-for-presenters .card-body {
    padding: 1.5rem;
  }
}

.freebirdFormviewerViewCenteredContent {
  margin: 0 !important;
}
</style>
