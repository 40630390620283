<template>
  <div class="wrapper fall-workshop">
    <div class="page-header">
      <div class="page-header-image" style="background-image: url('/img/capitolreef.jpeg')"></div>
      <div class="filter-layer"></div>
      <div class="decoration-shapes">
        <div class="shape shape-1"></div>
        <div class="shape shape-2"></div>
        <div class="shape shape-3"></div>
        <div class="shape shape-4"></div>
      </div>
      <div class="content-center">
        <div class="container">
        <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center">
              <div class="event-badge mb-5">September 27, 2025</div>
              <div class="title-wrapper">
                <h1 class="title display-3 text-white">UMC Fall Workshop</h1>
                <div class="title-decoration"></div>
              </div>
              <h2 class="subtitle mb-4">Montessori Without Borders</h2>
              <div class="event-details mt-4">
                <div class="event-detail-item">
                  <i class="now-ui-icons ui-1_calendar-60"></i>
                  <span>Saturday, September 27</span>
                </div>
                <div class="event-detail-item">
                  <i class="now-ui-icons tech_watch-time"></i>
                  <span>8:30 AM - 3:00 PM</span>
                </div>
                <div class="event-detail-item">
                  <i class="now-ui-icons location_pin"></i>
                  <span>TBD</span>
                </div>
              </div>
              <div class="header-btns mt-5">
                <a
                  target="_blank"
                  class="btn btn-primary btn-lg btn-round text-white"
                  href="https://form.jotform.com/231056427007145"
                ><i class="now-ui-icons shopping_tag-content mr-2"></i><strong>Register Now</strong></a>
                <a
                  target="_blank"
                  class="btn btn-primary btn-lg btn-round text-white"
                  href="downloads/2025 UMC Fall Conference Flyers.png"
                ><i class="now-ui-icons arrows-1_cloud-download-93 mr-2"></i><strong>Download Flyer</strong></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="section section-about-event">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="now-ui-icons education_hat"></i>
              </div>
              <div class="description">
                <h4 class="info-title">Professional Development</h4>
                <p>Join fellow Montessori educators for a day of learning, sharing, and growth.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="info info-horizontal">
              <div class="icon icon-success">
                <i class="now-ui-icons business_bulb-63"></i>
              </div>
              <div class="description">
                <h4 class="info-title">Innovative Ideas</h4>
                <p>Discover new approaches to bringing Montessori education to diverse communities.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 text-center">
        <a
          target="_blank"
              class="btn btn-outline-primary btn-round"
          href="downloads/2025 UMC Fall Conference Flyers.png"
            ><i class="now-ui-icons arrows-1_cloud-download-93 mr-2"></i><strong>Download Flyer</strong></a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="section section-keynote bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <span class="badge badge-primary">Featured Speaker</span>
            <h2 class="title">Meet Our Keynote</h2>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-5 col-sm-12 text-center mb-4 mb-md-0">
            <div class="speaker-img-container">
              <img src="/img/Nikki.jpg" alt="Nikki Conyers" class="img-fluid speaker-img">
            </div>
          </div>
          <div class="col-md-7 col-sm-12">
            <h3 class="speaker-name">Nikki Conyers, MEd</h3>
            <p class="speaker-title mb-4">Founder, Montessori on Wheels</p>
            <p>
              An innovative educator who brings the Montessori learning experience directly to underserved communities. With a passion for accessible education, she transforms traditional classrooms by offering children a mobile learning environment that fosters creativity, independence, and curiosity.
            </p>
            <p>
              Through her dedication, Nikki is reshaping the future of education, ensuring that every child has the opportunity to thrive regardless of their circumstances or location. Her keynote "Montessori Without Borders" will inspire us to think beyond traditional boundaries of Montessori education.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-schedule">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <span class="badge badge-primary">Full Day Event</span>
            <h2 class="title">Event Schedule</h2>
            <p class="lead">Join us for a full day of learning and collaboration</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card no-transition">
              <div class="card-body">
                <div class="timeline">
                  <div class="timeline-item">
                    <div class="timeline-badge bg-primary">
                      <i class="now-ui-icons travel_info"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">8:30 - 9:00 AM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Check-in & Continental Breakfast</strong></p>
                        <p class="text-muted">Network with fellow educators over coffee and light refreshments</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="timeline-item">
                    <div class="timeline-badge bg-primary">
                      <i class="now-ui-icons media-1_button-play"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">9:15 - 9:30 AM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Opening Remarks</strong></p>
                        <p class="text-muted">Welcome and introduction to the day's events</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="timeline-item">
                    <div class="timeline-badge bg-success">
                      <i class="now-ui-icons objects_spaceship"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">9:30 - 11:30 AM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Keynote: Nikki Conyers</strong></p>
                        <h5 class="text-primary">"Montessori Without Borders"</h5>
                        <p>
                          Join Nikki Conyers, M.Ed., founder of Montessori on Wheels, as she shares her inspiring journey of bringing Montessori education to underserved communities. Learn how her innovative mobile classroom approach has transformed access to quality education and discover practical strategies for expanding Montessori's reach beyond traditional boundaries.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="timeline-item">
                    <div class="timeline-badge bg-warning">
                      <i class="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">11:30 - 12:30 PM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Lunch Break</strong></p>
                        <p class="text-muted">Catered lunch provided for all attendees</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="timeline-item">
                    <div class="timeline-badge bg-danger">
                      <i class="now-ui-icons design_bullet-list-67"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">12:30 - 2:30 PM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Breakout Sessions</strong></p>
                        <div class="table-responsive">
                          <table class="table table-hover">
                            <tbody>
                              <tr>
                                <td><strong>Infant/Toddler</strong></td>
                                <td>Richelle Segura</td>
                              </tr>
                              <tr>
                                <td><strong>Early Childhood</strong></td>
                                <td>Karen Raemer</td>
                              </tr>
                              <tr>
                                <td><strong>Lower/Upper Elementary</strong></td>
                                <td>Tiffany Tutle & Jalee Schofield</td>
                              </tr>
                              <tr>
                                <td><strong>Middle/High School</strong></td>
                                <td>Carl Young & Joshi Haskell</td>
                              </tr>
                              <tr>
                                <td><strong>Administrators</strong></td>
                                <td>Schools in Community - The Art of Gathering</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="timeline-item">
                    <div class="timeline-badge bg-info">
                      <i class="now-ui-icons ui-2_like"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">2:30 - 3:00 PM</h4>
                      </div>
                      <div class="timeline-body">
                        <p><strong>Closing Remarks</strong></p>
                        <p class="text-muted">Summary of key takeaways and next steps</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-registration">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mb-3">
            <span class="badge badge-primary">Registration</span>
            <h2 class="title text-white mb-2">Secure Your Spot</h2>
            <p class="lead mb-0">Join us for this transformative professional development experience</p>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-8 col-md-10 col-sm-12">
            <div class="pricing-card">
              <div class="pricing-card-header">
                <h3 class="registration-title">Workshop Registration</h3>
              </div>
              <div class="pricing-card-body">
                <div class="pricing-timeline">
                  <div class="pricing-option" :class="{ primary: isEarlyBird }">
                    <div class="option-badge">Early Bird</div>
                    <div class="option-price"><span :class="{ 'original-price': !isEarlyBird }">$100</span></div>
                    <div class="option-period">Until August 29, 2025</div>
                  </div>
                  
                  <div class="pricing-arrow">
                    <i class="now-ui-icons arrows-1_minimal-right"></i>
                  </div>
                  
                  <div class="pricing-option" :class="{ primary: !isEarlyBird }">
                    <div class="option-badge">Regular</div>
                    <div class="option-price"><span :class="{ 'original-price': isEarlyBird }">$115</span></div>
                    <div class="option-period">After August 29, 2025</div>
                  </div>
                </div>
                <p class="refund-policy mt-4">
              Our volunteers are working to bring you a professional conference
              at the lowest possible cost. Therefore we cannot offer refunds.
            </p>
                <div class="text-center mt-4">
            <a
              target="_blank"
                    class="btn btn-primary btn-lg btn-round"
              href="https://form.jotform.com/231056427007145"
                  ><i class="now-ui-icons shopping_cart-simple mr-2"></i><strong>Register Now</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  name: "fall-workshop",
  bodyClass: "fall-workshop",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {
      form: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },
  computed: {
    isEarlyBird() {
      const today = new Date();
      const earlyBirdEndDate = new Date('2025-08-30'); // August 29 + 1 day to include the full day
      return today < earlyBirdEndDate;
    }
  },
  metaInfo: {
    title: "Utah Montessori - Fall Workshop",
    meta: [
      { name: "description", content: "Join the UMC Fall Workshop 2025: Montessori Without Borders - An inspiring professional development event featuring keynote speaker Nikki Conyers and specialized breakout sessions for Montessori educators." },
    ],
  },
};
</script>
<style>
.fall-workshop .page-header {
  height: 80vh;
  max-height: 700px;
  position: relative;
  overflow: hidden;
}

.fall-workshop .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: saturate(1.2) contrast(1.1);
}

.fall-workshop .filter-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(50, 90, 75, 0.85) 0%, rgba(65, 90, 85, 0.85) 100%);
}

.fall-workshop .filter-layer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/img/dots.png');
  background-size: 40px;
  opacity: 0.1;
  z-index: -1;
}

.fall-workshop .filter-layer::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: radial-gradient(circle at top right, rgba(249, 99, 50, 0.15), transparent 70%);
  z-index: -1;
}

.fall-workshop .content-center {
  position: relative;
  z-index: 10;
  padding-top: 8rem;
}

.fall-workshop .title-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  margin: 10px 0 25px;
}

.fall-workshop .title-decoration {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid rgba(249, 99, 50, 0.5);
  border-radius: 4px;
  z-index: -1;
}

.fall-workshop .title-decoration::before {
  content: '';
  position: absolute;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  top: -8px;
  left: -8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.fall-workshop .title.display-3 {
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;
  line-height: 1.2;
  font-size: 2.5rem;
  padding: 0;
}

.fall-workshop .subtitle {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 30px;
}

.fall-workshop .subtitle::before,
.fall-workshop .subtitle::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 1px;
  background-color: #f96332;
  top: 50%;
}

.fall-workshop .subtitle::before {
  left: 0;
}

.fall-workshop .subtitle::after {
  right: 0;
}

.fall-workshop .event-badge {
  display: inline-block;
  background-color: #f96332;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85rem;
  box-shadow: 0 5px 15px rgba(249, 99, 50, 0.3);
}

.fall-workshop .event-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  position: relative;
}

.fall-workshop .event-detail-item {
  display: flex;
  align-items: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 12px 25px;
  border-radius: 50px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.fall-workshop .event-detail-item:hover {
  transform: translateY(-3px);
  background-color: rgba(255, 255, 255, 0.2);
}

.fall-workshop .event-detail-item i {
  margin-right: 12px;
  color: #f96332;
  font-size: 1.2rem;
}

.fall-workshop .header-btns {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.fall-workshop .header-btns .btn {
  margin: 0;
}

.fall-workshop .header-btns .btn-primary {
  box-shadow: 0 5px 15px rgba(249, 99, 50, 0.4);
  transition: all 0.3s ease;
}

.fall-workshop .header-btns .btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(249, 99, 50, 0.5);
}

.fall-workshop .btn-round {
  border-radius: 30px;
}

.fall-workshop .section {
  padding: 80px 0;
}

.fall-workshop .section-about-event {
  margin-top: -50px;
  position: relative;
  z-index: 4;
  background: white;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  padding: 60px 0;
  margin-bottom: 0;
}

.fall-workshop .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

.fall-workshop .icon-primary {
  background: linear-gradient(to right, #5a706b, #4c7261);
  color: white;
}

.fall-workshop .icon-success {
  background: linear-gradient(to right, #18ce0f, #00bf9a);
  color: white;
}

.fall-workshop .info-horizontal {
  display: flex;
  margin-bottom: 30px;
}

.fall-workshop .bg-light {
  background-color: #f8f9fa !important;
}

.fall-workshop .section-keynote {
  padding: 100px 0;
}

.fall-workshop .speaker-img-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  padding: 8px;
  background: white;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 10px 25px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.fall-workshop .speaker-img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.fall-workshop .speaker-social {
  position: absolute;
  bottom: 20px;
  right: 0;
  display: flex;
  gap: 10px;
}

.fall-workshop .speaker-name {
  font-size: 2.2rem;
  color: #2c2c2c;
  margin-bottom: 0.5rem;
}

.fall-workshop .speaker-title {
  font-size: 1.1rem;
  color: #f96332;
  font-weight: 500;
}

.fall-workshop .badge {
  padding: 0.5em 1em;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 30px;
  margin-bottom: 15px;
}

.fall-workshop .timeline {
  position: relative;
  padding: 20px 0;
}

.fall-workshop .timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: linear-gradient(to bottom, #5a706b, #4c7261);
  left: 50px;
  margin-left: -2px;
  border-radius: 4px;
}

.fall-workshop .timeline-item {
  position: relative;
  margin-bottom: 40px;
}

.fall-workshop .timeline-badge {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  left: 50px;
  margin-left: -25px;
  color: #fff;
  z-index: 1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 0, 0, 0.2);
}

.fall-workshop .timeline-badge i {
  font-size: 1.25rem;
}

.fall-workshop .timeline-panel {
  margin-left: 100px;
  padding: 20px;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.07);
  background: white;
  transition: all 0.3s ease;
  border-top: 3px solid transparent;
}

.fall-workshop .timeline-panel:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  border-top: 3px solid #5a706b;
}

.fall-workshop .timeline-title {
  font-weight: bold;
  color: #5a706b;
}

.fall-workshop .timeline-panel .text-primary {
  color: #f96332 !important;
}

.fall-workshop .section-registration {
  background: linear-gradient(135deg, #5a706b 0%, #4c7261 100%);
  position: relative;
  padding: 60px 0;
  color: white;
}

.fall-workshop .section-registration::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/img/dots.png');
  background-size: cover;
  opacity: 0.1;
}

.fall-workshop .badge-neutral {
  background-color: white;
  color: #5a706b;
}

.fall-workshop .pricing-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.fall-workshop .pricing-card-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.fall-workshop .registration-title {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
}

.fall-workshop .pricing-card-body {
  padding: 30px;
}

.fall-workshop .pricing-timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.fall-workshop .pricing-option {
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  flex: 1;
  min-width: 200px;
  position: relative;
  transition: all 0.3s ease;
}

.fall-workshop .pricing-option.primary {
  background-color: rgba(255, 255, 255, 0.15);
  transform: scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.fall-workshop .pricing-option:hover {
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 0.15);
}

.fall-workshop .option-badge {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f96332;
  color: white;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 5px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 30px;
}

.fall-workshop .option-price {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 15px 0 10px;
}

.fall-workshop .option-period {
  font-size: 0.9rem;
  opacity: 0.8;
}

.fall-workshop .pricing-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  position: relative;
  margin: 0 10px;
}

.fall-workshop .pricing-arrow i {
  background-color: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
  text-align: center;
  position: relative;
  left: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.fall-workshop .refund-policy {
  margin: 30px 0;
  font-size: 0.9rem;
  opacity: 0.8;
  text-align: center;
}

.fall-workshop .card {
  border: none;
  border-radius: 6px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.07);
}

.fall-workshop .card.no-transition {
  box-shadow: none;
}

.fall-workshop .card-header {
  border-radius: 6px 6px 0 0;
  padding: 20px;
}

.fall-workshop .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.fall-workshop .lead {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.6;
}

.fall-workshop h2.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.fall-workshop .timeline-body {
  word-wrap: break-word;
}

.fall-workshop .timeline-body .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.fall-workshop .timeline-body .table {
  min-width: 100%;
  margin-bottom: 0;
}

.fall-workshop .original-price {
  position: relative;
  color: rgba(255, 255, 255, 0.7);
}

.fall-workshop .original-price::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -5%;
  width: 110%;
  height: 3px;
  background: #f96332;
  border-radius: 2px;
  transform: rotate(-10deg);
  animation: strikethrough 1s ease-out forwards;
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes strikethrough {
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: 110%;
    left: -5%;
  }
}

@media (max-width: 768px) {
  .fall-workshop .event-detail-item {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .fall-workshop .timeline-panel {
    margin-left: 70px;
    padding: 15px;
  }
  
  .fall-workshop .section-about-event {
    margin-top: 0;
  }
  
  .fall-workshop .pricing-card {
    margin-bottom: 20px;
  }
  
  .fall-workshop .page-header {
    max-height: 600px;
  }
  
  .fall-workshop .header-btns {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .fall-workshop .pricing-timeline {
    flex-direction: column;
  }
  
  .fall-workshop .pricing-arrow {
    transform: rotate(90deg);
    margin: 15px 0;
    width: 40px;
    height: 40px;
  }
  
  .fall-workshop .pricing-arrow i {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  
  .fall-workshop .pricing-option {
    width: 100%;
  }
  
  .fall-workshop .pricing-card-body {
    padding: 20px 15px;
  }

  .fall-workshop .title.display-3 {
    font-size: 2rem;
  }

  .fall-workshop .subtitle {
    font-size: 1.2rem;
  }

  .fall-workshop h2.title {
    font-size: 2rem;
  }

  .fall-workshop .speaker-name {
    font-size: 1.8rem;
    margin-top: 1rem;
  }

  .fall-workshop .section {
    padding: 50px 0;
  }

  .fall-workshop .section-keynote {
    padding: 50px 0;
  }

  .fall-workshop .timeline:before {
    left: 30px;
  }

  .fall-workshop .timeline-badge {
    left: 30px;
  }

  .fall-workshop .timeline-panel {
    margin-left: 60px;
  }

  .fall-workshop .timeline-title {
    font-size: 1.1rem;
  }

  .fall-workshop .table-responsive {
    font-size: 0.9rem;
  }

  .fall-workshop .option-price {
    font-size: 2.5rem;
  }

  .fall-workshop .speaker-img-container {
    max-width: 220px;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .fall-workshop .speaker-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .fall-workshop .lead {
    font-size: 1.1rem;
  }

  .fall-workshop .shape {
    display: none;
  }

  .fall-workshop .content-center {
    padding-top: 10rem;
  }
}

@media (max-width: 480px) {
  .fall-workshop .title-wrapper {
    padding: 10px 20px;
  }

  .fall-workshop .title.display-3 {
    font-size: 1.8rem;
  }

  .fall-workshop .subtitle {
    font-size: 1rem;
    padding: 0 20px;
  }

  .fall-workshop .event-badge {
    padding: 8px 15px;
    font-size: 0.75rem;
  }

  .fall-workshop .event-detail-item {
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .fall-workshop .timeline-panel {
    padding: 12px;
    margin-left: 50px;
  }

  .fall-workshop .timeline:before {
    left: 22px;
  }

  .fall-workshop .timeline-badge {
    left: 22px;
    width: 40px;
    height: 40px;
  }

  .fall-workshop .timeline-badge i {
    font-size: 1rem;
  }

  .fall-workshop .option-price {
    font-size: 2rem;
  }

  .fall-workshop .registration-title {
    font-size: 1.5rem;
  }

  .fall-workshop .content-center {
    padding-top: 12rem;
  }
}

/* Override the framework styles */
.fall-workshop [class*=pricing-] {
  padding: 20px 10px !important;
}

/* Make sure the row spacing is minimal */
.fall-workshop .compact-registration .row.justify-content-center {
  margin-top: 0.5rem !important;
}

.fall-workshop .decoration-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
  pointer-events: none;
}

.fall-workshop .shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
}

.fall-workshop .shape-1 {
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, #f96332 0%, transparent 70%);
  top: 10%;
  right: 10%;
  animation: float 15s infinite ease-in-out;
}

.fall-workshop .shape-2 {
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, #5a706b 0%, transparent 70%);
  bottom: 15%;
  right: 20%;
  animation: float 12s infinite ease-in-out reverse;
}

.fall-workshop .shape-3 {
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, #4c7261 0%, transparent 70%);
  top: 40%;
  left: 15%;
  animation: float 10s infinite ease-in-out;
}

.fall-workshop .shape-4 {
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, #5a706b 0%, transparent 70%);
  bottom: 25%;
  left: 10%;
  animation: float 8s infinite ease-in-out reverse;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(15px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.fall-workshop .timeline-badge.bg-primary {
  background: linear-gradient(135deg, #5a706b, #4c7261) !important;
}

.fall-workshop .timeline-badge.bg-success {
  background: linear-gradient(135deg, #5a706b, #4c7261) !important;
}

.fall-workshop .timeline-badge.bg-warning {
  background: linear-gradient(135deg, #5a706b, #4c7261) !important;
}

.fall-workshop .timeline-badge.bg-danger {
  background: linear-gradient(135deg, #5a706b, #4c7261) !important;
}

.fall-workshop .timeline-badge.bg-info {
  background: linear-gradient(135deg, #5a706b, #4c7261) !important;
}
</style>
